import React from "react";
import image from "../../set/Ceo.jpg";
import image1 from "../../set/pc.jpg";
import image2 from "../../set/guide.jpeg";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import "./guides.css";



const Guides = () => {
  const data = [
    {
      imgg: image,
      name: "Makenga KAZIYABWANA",
      title: "Founder & Travel guide",
      facebook: "https://www.facebook.com/profile.php?id=61555987182472&mibextid=rS40aB7S9Ucbxw6v",
      instagram:"https://www.instagram.com/turacos_tour_africa_safaris?igsh=YzljYTk1ODg3Zg==",
      whatsapp:"https://wa.link/f0pjp6"
    },
    {
      imgg: image2,
      name: "Jean d'amour RWIBUTSO",
      title: "Travel guide",
      facebook: "https://www.facebook.com/profile.php?id=61555987182472&mibextid=rS40aB7S9Ucbxw6v",
      instagram:"https://www.instagram.com/turacos_tour_africa_safaris?igsh=YzljYTk1ODg3Zg==",
      whatsapp:"https://wa.link/f0pjp6"
    },
    {
      imgg: image1,
      name: "ArtCodE",
      title: "IT Assistant",
      facebook: "#",
      instagram:"https://www.instagram.com/niyihoraho_heroic/",
      whatsapp:"https://wa.link/q240w2"
    },
  ];
  return (
    <section className="guides">
      <div className="container">
        <div className="text">
          <h2>GUIDES</h2>
          <h1>Our Travel Guides</h1>
        </div>
        <div className="content-guides">
          {data.map((d, index) => (
            <div className="guide" key={index}>
              <div className="image">
                <img src={d.imgg} alt="" />
                
                  <div className="icons">
                   <a href={d.instagram}><FaInstagram className="icon"/></a>
                   <a href={d.whatsapp}><FaWhatsapp className="icon"/></a>
                   <a href={d.facebook}><FaFacebookF className="icon"/></a>               
                </div>
              </div>
              <div className="title">
                <h1>{d.name}</h1>
                <h2>{d.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Guides;
