import React from "react";
import "./map.css";

const Map = () => {
  return (
    <section className="mapping">
      <div className="container">
        <div className="map" />
        <h5>Agency Location</h5>
        <div className="map-responsive">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3987.2337368600515!2d29.346694946289062!3d-2.0624327659606934!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dd2960969ed3f5%3A0xdf96c286664d5b8a!2sW8QW%2B2V3%2C%20Gitesi!5e0!3m2!1sen!2srw!4v1713305719424!5m2!1sen!2srw"
          width="1100"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Map;
