import React, { useEffect,useState } from "react";
import Axios from "axios";
import "./blogpage.css";
import image from "../../set/blog-2.jpg";
import image1 from "../../set/blog-4.jpg";
import image2 from "../../set/blog-5.jpg";

const BlogPage = () => {

  const [blog, setBlog] = useState([]);

  useEffect(() => {
    Axios.get("https://turacosapi.turacostours.com/BlogSelectLimit")
      .then((res) => setBlog(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="blogs">
      <div className="container">
        <div className="textt">
          <h1>Our Blog</h1>
        </div>
        <div className="content-blog">
          {blog.map((d, index) => (
            <div className="blog" key={index}>
              <div className="image">
                <img src={`https://turacosapi.turacostours.com/${d.image}`} alt="" />
              </div>
              <div className="title">
                <h1>{d.title}</h1>
                <p>{d.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogPage;
